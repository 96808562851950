.container {
    background: url('../../assets/back.jpg') no-repeat center /cover;
    width: 100%;
    height: 100vh;
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    background: #fff;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 5px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 5px rgba(34, 60, 80, 0.2);
}

.inputWrapper {
    padding-bottom: 30px;
}