.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.form {
    padding: 20px 0px;
}

.contactsHead {
    background: #ececec;
    margin-top: 30px;
    padding: 10px 10px;
    color: #333333;
}

.actionHead {
    text-align: center;
}

.button {
    width: 100%;
}